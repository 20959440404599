import React from 'react';
import { NavLink } from 'react-router-dom';

function SideNav(props) {
    return (
        <nav className="nxl-navigation">
            <div className="navbar-wrapper">
                <div className="m-header">
                    <a href="index.html" className="b-brand">
                        <h3>Supply Management</h3>
                        {/* <img src="assets/images/logo-full.png" alt="" className="logo logo-lg" />
                        <img src="assets/images/logo-abbr.png" alt="" className="logo logo-sm" /> */}
                    </a>
                </div>
                <div className="navbar-content">
                    <ul className="nxl-navbar">
                        <li className="nxl-item nxl-caption">
                            <label>Navigation</label>
                        </li>
                        <li className="nxl-item nxl-hasmenu">
                            <NavLink to={"/dashboard"} className="nxl-link">
                                <span className="nxl-micon"><i className="feather-airplay"></i></span>
                                <span className="nxl-mtext">Dashboards</span><span className="nxl-arrow"></span>
                            </NavLink>
                        </li>
                        <li className="nxl-item nxl-hasmenu">
                            <NavLink to={"/inventory"} className="nxl-link">
                                <span className="nxl-micon"><i className="feather-cast"></i></span>
                                <span className="nxl-mtext">Inventory</span><span className="nxl-arrow"></span>
                            </NavLink>
                        </li>
                        <li className="nxl-item nxl-hasmenu">
                            <NavLink to={"/projects"} className="nxl-link">
                                <span className="nxl-micon"><i className="feather-airplay"></i></span>
                                <span className="nxl-mtext">Projects</span><span className="nxl-arrow"></span>
                            </NavLink>
                        </li>
                        {/* <li className="nxl-item nxl-hasmenu">
                            <NavLink to={"/coming-soon"} className="nxl-link">
                                <span className="nxl-micon"><i className="feather-cast"></i></span>
                                <span className="nxl-mtext">Coming Soon</span><span className="nxl-arrow"></span>
                            </NavLink>
                        </li> */}
                        {/* <li className="nxl-item nxl-hasmenu">
                            <a href="javascript:void(0);" className="nxl-link">
                                <span className="nxl-micon"><i className="feather-cast"></i></span>
                                <span className="nxl-mtext">Reports</span><span className="nxl-arrow"><i className="feather-chevron-right"></i></span>
                            </a>
                            <ul className="nxl-submenu">
                                <li className="nxl-item"><a className="nxl-link" href="reports-sales.html">Sales Report</a></li>
                                <li className="nxl-item"><a className="nxl-link" href="reports-leads.html">Leads Report</a></li>
                                <li className="nxl-item"><a className="nxl-link" href="reports-project.html">Project Report</a></li>
                                <li className="nxl-item"><a className="nxl-link" href="reports-timesheets.html">Timesheets Report</a></li>
                            </ul>
                        </li>
                        <li className="nxl-item nxl-hasmenu">
                            <a href="javascript:void(0);" className="nxl-link">
                                <span className="nxl-micon"><i className="feather-send"></i></span>
                                <span className="nxl-mtext">Applications</span><span className="nxl-arrow"><i className="feather-chevron-right"></i></span>
                            </a>
                            <ul className="nxl-submenu">
                                <li className="nxl-item"><a className="nxl-link" href="apps-chat.html">Chat</a></li>
                                <li className="nxl-item"><a className="nxl-link" href="apps-email.html">Email</a></li>
                                <li className="nxl-item"><a className="nxl-link" href="apps-tasks.html">Tasks</a></li>
                                <li className="nxl-item"><a className="nxl-link" href="apps-notes.html">Notes</a></li>
                                <li className="nxl-item"><a className="nxl-link" href="apps-storage.html">Storage</a></li>
                                <li className="nxl-item"><a className="nxl-link" href="apps-calendar.html">Calendar</a></li>
                            </ul>
                        </li>
                        <li className="nxl-item nxl-hasmenu">
                            <a href="javascript:void(0);" className="nxl-link">
                                <span className="nxl-micon"><i className="feather-at-sign"></i></span>
                                <span className="nxl-mtext">Proposal</span><span className="nxl-arrow"><i className="feather-chevron-right"></i></span>
                            </a>
                            <ul className="nxl-submenu">
                                <li className="nxl-item"><a className="nxl-link" href="proposal.html">Proposal</a></li>
                                <li className="nxl-item"><a className="nxl-link" href="proposal-view.html">Proposal View</a></li>
                                <li className="nxl-item"><a className="nxl-link" href="proposal-edit.html">Proposal Edit</a></li>
                                <li className="nxl-item"><a className="nxl-link" href="proposal-create.html">Proposal Create</a></li>
                            </ul>
                        </li>
                        <li className="nxl-item nxl-hasmenu">
                            <a href="javascript:void(0);" className="nxl-link">
                                <span className="nxl-micon"><i className="feather-dollar-sign"></i></span>
                                <span className="nxl-mtext">Payment</span><span className="nxl-arrow"><i className="feather-chevron-right"></i></span>
                            </a>
                            <ul className="nxl-submenu">
                                <li className="nxl-item"><a className="nxl-link" href="payment.html">Payment</a></li>
                                <li className="nxl-item"><a className="nxl-link" href="invoice-view.html">Invoice View</a></li>
                                <li className="nxl-item"><a className="nxl-link" href="invoice-create.html">Invoice Create</a></li>
                            </ul>
                        </li>
                        <li className="nxl-item nxl-hasmenu">
                            <a href="javascript:void(0);" className="nxl-link">
                                <span className="nxl-micon"><i className="feather-users"></i></span>
                                <span className="nxl-mtext">Customers</span><span className="nxl-arrow"><i className="feather-chevron-right"></i></span>
                            </a>
                            <ul className="nxl-submenu">
                                <li className="nxl-item"><a className="nxl-link" href="customers.html">Customers</a></li>
                                <li className="nxl-item"><a className="nxl-link" href="customers-view.html">Customers View</a></li>
                                <li className="nxl-item"><a className="nxl-link" href="customers-create.html">Customers Create</a></li>
                            </ul>
                        </li>
                        <li className="nxl-item nxl-hasmenu">
                            <a href="javascript:void(0);" className="nxl-link">
                                <span className="nxl-micon"><i className="feather-alert-circle"></i></span>
                                <span className="nxl-mtext">Leads</span><span className="nxl-arrow"><i className="feather-chevron-right"></i></span>
                            </a>
                            <ul className="nxl-submenu">
                                <li className="nxl-item"><a className="nxl-link" href="leads.html">Leads</a></li>
                                <li className="nxl-item"><a className="nxl-link" href="leads-view.html">Leads View</a></li>
                                <li className="nxl-item"><a className="nxl-link" href="leads-create.html">Leads Create</a></li>
                            </ul>
                        </li>
                        <li className="nxl-item nxl-hasmenu">
                            <a href="javascript:void(0);" className="nxl-link">
                                <span className="nxl-micon"><i className="feather-briefcase"></i></span>
                                <span className="nxl-mtext">Projects</span><span className="nxl-arrow"><i className="feather-chevron-right"></i></span>
                            </a>
                            <ul className="nxl-submenu">
                                <li className="nxl-item"><a className="nxl-link" href="projects.html">Projects</a></li>
                                <li className="nxl-item"><a className="nxl-link" href="projects-view.html">Projects View</a></li>
                                <li className="nxl-item"><a className="nxl-link" href="projects-create.html">Projects Create</a></li>
                            </ul>
                        </li>
                        <li className="nxl-item nxl-hasmenu">
                            <a href="javascript:void(0);" className="nxl-link">
                                <span className="nxl-micon"><i className="feather-layout"></i></span>
                                <span className="nxl-mtext">Widgets</span><span className="nxl-arrow"><i className="feather-chevron-right"></i></span>
                            </a>
                            <ul className="nxl-submenu">
                                <li className="nxl-item"><a className="nxl-link" href="widgets-lists.html">Lists</a></li>
                                <li className="nxl-item"><a className="nxl-link" href="widgets-tables.html">Tables</a></li>
                                <li className="nxl-item"><a className="nxl-link" href="widgets-charts.html">Charts</a></li>
                                <li className="nxl-item"><a className="nxl-link" href="widgets-statistics.html">Statistics</a></li>
                                <li className="nxl-item"><a className="nxl-link" href="widgets-miscellaneous.html">Miscellaneous</a></li>
                            </ul>
                        </li>
                        <li className="nxl-item nxl-hasmenu">
                            <a href="javascript:void(0);" className="nxl-link">
                                <span className="nxl-micon"><i className="feather-settings"></i></span>
                                <span className="nxl-mtext">Settings</span><span className="nxl-arrow"><i className="feather-chevron-right"></i></span>
                            </a>
                            <ul className="nxl-submenu">
                                <li className="nxl-item"><a className="nxl-link" href="settings-general.html">General</a></li>
                                <li className="nxl-item"><a className="nxl-link" href="settings-seo.html">SEO</a></li>
                                <li className="nxl-item"><a className="nxl-link" href="settings-tags.html">Tags</a></li>
                                <li className="nxl-item"><a className="nxl-link" href="settings-email.html">Email</a></li>
                                <li className="nxl-item"><a className="nxl-link" href="settings-tasks.html">Tasks</a></li>
                                <li className="nxl-item"><a className="nxl-link" href="settings-leads.html">Leads</a></li>
                                <li className="nxl-item"><a className="nxl-link" href="settings-support.html">Support</a></li>
                                <li className="nxl-item"><a className="nxl-link" href="settings-finance.html">Finance</a></li>
                                <li className="nxl-item"><a className="nxl-link" href="settings-gateways.html">Gateways</a></li>
                                <li className="nxl-item"><a className="nxl-link" href="settings-customers.html">Customers</a></li>
                                <li className="nxl-item"><a className="nxl-link" href="settings-localization.html">Localization</a></li>
                                <li className="nxl-item"><a className="nxl-link" href="settings-recaptcha.html">reCAPTCHA</a></li>
                                <li className="nxl-item"><a className="nxl-link" href="settings-miscellaneous.html">Miscellaneous</a></li>
                            </ul>
                        </li>
                        <li className="nxl-item nxl-hasmenu">
                            <a href="javascript:void(0);" className="nxl-link">
                                <span className="nxl-micon"><i className="feather-power"></i></span>
                                <span className="nxl-mtext">Authentication</span><span className="nxl-arrow"><i className="feather-chevron-right"></i></span>
                            </a>
                            <ul className="nxl-submenu">
                                <li className="nxl-item nxl-hasmenu">
                                    <a href="javascript:void(0);" className="nxl-link">
                                        <span className="nxl-mtext">Login</span><span className="nxl-arrow"><i className="feather-chevron-right"></i></span>
                                    </a>
                                    <ul className="nxl-submenu">
                                        <li className="nxl-item"><a className="nxl-link" href="./auth-login-cover.html">Cover</a></li>
                                        <li className="nxl-item"><a className="nxl-link" href="./auth-login-minimal.html">Minimal</a></li>
                                        <li className="nxl-item"><a className="nxl-link" href="./auth-login-creative.html">Creative</a></li>
                                    </ul>
                                </li>
                                <li className="nxl-item nxl-hasmenu">
                                    <a href="javascript:void(0);" className="nxl-link">
                                        <span className="nxl-mtext">Register</span><span className="nxl-arrow"><i className="feather-chevron-right"></i></span>
                                    </a>
                                    <ul className="nxl-submenu">
                                        <li className="nxl-item"><a className="nxl-link" href="./auth-register-cover.html">Cover</a></li>
                                        <li className="nxl-item"><a className="nxl-link" href="./auth-register-minimal.html">Minimal</a></li>
                                        <li className="nxl-item"><a className="nxl-link" href="./auth-register-creative.html">Creative</a></li>
                                    </ul>
                                </li>
                                <li className="nxl-item nxl-hasmenu">
                                    <a href="javascript:void(0);" className="nxl-link">
                                        <span className="nxl-mtext">Error-404</span><span className="nxl-arrow"><i className="feather-chevron-right"></i></span>
                                    </a>
                                    <ul className="nxl-submenu">
                                        <li className="nxl-item"><a className="nxl-link" href="./auth-404-cover.html">Cover</a></li>
                                        <li className="nxl-item"><a className="nxl-link" href="./auth-404-minimal.html">Minimal</a></li>
                                        <li className="nxl-item"><a className="nxl-link" href="./auth-404-creative.html">Creative</a></li>
                                    </ul>
                                </li>
                                <li className="nxl-item nxl-hasmenu">
                                    <a href="javascript:void(0);" className="nxl-link">
                                        <span className="nxl-mtext">Reset Pass</span><span className="nxl-arrow"><i className="feather-chevron-right"></i></span>
                                    </a>
                                    <ul className="nxl-submenu">
                                        <li className="nxl-item"><a className="nxl-link" href="./auth-reset-cover.html">Cover</a></li>
                                        <li className="nxl-item"><a className="nxl-link" href="./auth-reset-minimal.html">Minimal</a></li>
                                        <li className="nxl-item"><a className="nxl-link" href="./auth-reset-creative.html">Creative</a></li>
                                    </ul>
                                </li>
                                <li className="nxl-item nxl-hasmenu">
                                    <a href="javascript:void(0);" className="nxl-link">
                                        <span className="nxl-mtext">Verify OTP</span><span className="nxl-arrow"><i className="feather-chevron-right"></i></span>
                                    </a>
                                    <ul className="nxl-submenu">
                                        <li className="nxl-item"><a className="nxl-link" href="./auth-verify-cover.html">Cover</a></li>
                                        <li className="nxl-item"><a className="nxl-link" href="./auth-verify-minimal.html">Minimal</a></li>
                                        <li className="nxl-item"><a className="nxl-link" href="./auth-verify-creative.html">Creative</a></li>
                                    </ul>
                                </li>
                                <li className="nxl-item nxl-hasmenu">
                                    <a href="javascript:void(0);" className="nxl-link">
                                        <span className="nxl-mtext">Maintenance</span><span className="nxl-arrow"><i className="feather-chevron-right"></i></span>
                                    </a>
                                    <ul className="nxl-submenu">
                                        <li className="nxl-item"><a className="nxl-link" href="./auth-maintenance-cover.html">Cover</a></li>
                                        <li className="nxl-item"><a className="nxl-link" href="./auth-maintenance-minimal.html">Minimal</a></li>
                                        <li className="nxl-item"><a className="nxl-link" href="./auth-maintenance-creative.html">Creative</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li className="nxl-item nxl-hasmenu">
                            <a href="javascript:void(0);" className="nxl-link">
                                <span className="nxl-micon"><i className="feather-life-buoy"></i></span>
                                <span className="nxl-mtext">Help Center</span><span className="nxl-arrow"><i className="feather-chevron-right"></i></span>
                            </a>
                            <ul className="nxl-submenu">
                                <li className="nxl-item"><a className="nxl-link" href="https://themeforest.net/user/flexilecode">Support</a></li>
                                <li className="nxl-item"><a className="nxl-link" href="help-knowledgebase.html">KnowledgeBase</a></li>
                                <li className="nxl-item"><a className="nxl-link" href="#">Documentations</a></li>
                            </ul>
                        </li> */}
                    </ul>
                    {/* <div className="card text-center">
                        <div className="card-body">
                            <i className="feather-sunrise fs-4 text-dark"></i>
                            <h6 className="mt-4 text-dark fw-bolder">Downloading Center</h6>
                            <p className="fs-11 my-3 text-dark">Duralux is a production ready CRM to get started up and running easily.</p>
                            <a href="javascript:void(0);" className="btn btn-primary text-dark w-100">Download Now</a>
                        </div>
                    </div> */}
                </div>
            </div>
        </nav>
    );
}

export default SideNav;