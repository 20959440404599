import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

function CreateProjects() {
    const location = useLocation();
    const navigate = useNavigate();
    const editData = location.state?.editData || null; // Safely access editData

    const initialItemState = editData
        ? { id: editData.Id, name: editData.Name, workOrders: editData.WorkOrders}
        : { id: 1, name: "", workOrders: "" };

    const [items, setItems] = useState([initialItemState]);

    useEffect(() => {
        if (editData) {
            setItems([{ id: editData.Id, name: editData.Name, workOrders: editData.WorkOrders }]);
        }
    }, [editData]);

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const updatedItems = [...items];
        updatedItems[index][name] = value;
        setItems(updatedItems);
    };

    const addItem = () => {
        setItems([...items, { id: items.length + 1, name: "", workOrders: "", price: "", minQty: "" }]);
    };

    const deleteItem = (index) => {
        const updatedItems = items.filter((_, i) => i !== index);
        setItems(updatedItems);
    };

    const handleSubmit = () => {
        let projects = JSON.parse(localStorage.getItem("projects")) || [];
        let lastId = 0;

        if (projects.length > 0) {
            lastId = Number(projects[projects.length - 1]?.Id?.split("#")?.[1]);
        }

        const jsonData = items.map((item, index) => ({
            Id: `#${lastId + index + 1}`,
            Name: item.name,
            WorkOrders: item.workOrders,
            // Price: item.price,
            // minQty: item.minQty,
            // Status: Number(item.workOrders) >= Number(item.minQty) ? "Available" : Number(item.workOrders) === 0 ? "Out of Stock" : "Low Stock"
        })).filter(o => o.Name);

        if (editData) {
            const index = projects.findIndex((item) => item.Id === editData.Id);
            if (index !== -1) {
                projects[index] = jsonData[0];
            }
        } else {
            projects = [...jsonData, ...projects];
        }

        localStorage.setItem("projects", JSON.stringify(projects));
        navigate('/projects', { replace: true });
    };

    return (
        <main className='nxl-container'>
            <div className='nxl-content'>
                <div className="page-header">
                    <div className="page-header-left d-flex align-items-center">
                        <div className="page-header-title">
                            <h5 className="m-b-10">{editData ? "Edit" : "Create"} Projects</h5>
                        </div>
                        <ul className="breadcrumb">
                            <li className="breadcrumb-item"><NavLink to={"/projects"}>Projects</NavLink></li>
                            <li className="breadcrumb-item">{editData ? "Edit" : "Create"} Projects</li>
                        </ul>
                    </div>
                    <div className="page-header-right ms-auto">
                        <div className="page-header-right-items">
                            <div className="d-flex d-md-none">
                                <a href="javascript:void(0)" className="page-header-right-close-toggle">
                                    <i className="feather-arrow-left me-2"></i>
                                    <span>Back</span>
                                </a>
                            </div>
                        </div>
                        <div className="d-md-none d-flex align-items-center">
                            <a href="javascript:void(0)" className="page-header-right-open-toggle">
                                <i className="feather-align-right fs-20"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="main-content">
                    <div className="col-12">
                        <div className="card stretch stretch-full">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="mb-4">
                                            <h5 className="fw-bold">{editData ? "Edit" : "Create"} Projects</h5>
                                            <span className="fs-12 text-muted">Add items to Projects</span>
                                        </div>
                                        <div className="table-responsive">
                                            <table className="table table-bordered overflow-hidden" id="tab_logic">
                                                <thead>
                                                    <tr className="single-item">
                                                        <th className="text-center" style={{ width: "100px" }}>Id</th>
                                                        <th className="text-center" style={{ width: "200px" }}>Name</th>
                                                        <th className="text-center wd-150">Work Orders</th>
                                                        {/* <th className="text-center wd-150">Price</th>
                                                        <th className="text-center wd-150">Min Quantity</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {items.map((item, index) => (
                                                        <tr key={index} id={`addr${index}`}>
                                                            <td>{item.id}</td>
                                                            <td><input type="text" name="name" value={item.name} placeholder="Product Name" className="form-control" onChange={(e) => handleInputChange(e, index)} /></td>
                                                            <td><input type="number" name="workOrders" value={item.workOrders} placeholder="Qty" className="form-control workOrders" step="1" min="1" onChange={(e) => handleInputChange(e, index)} /></td>
                                                            {/* <td><input type="number" name="price" value={item.price} placeholder="Unit Price" className="form-control price" step="1.00" onChange={(e) => handleInputChange(e, index)} /></td>
                                                            <td><input type="number" name="minQty" value={item.minQty} placeholder="0.00" className="form-control total" readOnly={false} onChange={(e) => handleInputChange(e, index)} /></td> */}
                                                            {!editData && <td><button type="button" className="btn btn-danger" onClick={() => deleteItem(index)}>Delete</button></td>}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="d-flex justify-content-end gap-2 mt-3">
                                            {!editData && <button id="add_row" className="btn btn-md btn-primary" onClick={addItem}>Add Items</button>}
                                            <button className="btn btn-md btn-secondary" onClick={handleSubmit}>{editData ? "Update" : "Submit"}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default CreateProjects;
