import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        // Check if token is already present
        const token = localStorage.getItem('token');
        if (token) {
            navigate('/dashboard');
        }
    }, [navigate]);

    const generateRandomToken = () => {
        return Math.random().toString(36).substr(2); // Simple random token generator
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Check credentials (for demo, use hardcoded values)
        if (email === 'admin@gmail.com' && password === '123456') {
            const token = generateRandomToken();
            localStorage.setItem('token', token);
            navigate('/dashboard');
        } else {
            setError('Invalid email or password');
        }
    };

    return (
        <main className="auth-minimal-wrapper">
            <div className="auth-minimal-inner">
                <div className="minimal-card-wrapper">
                    <div className="card mb-4 mt-5 mx-4 mx-sm-0 position-relative">
                        <div className="card-body p-sm-5">
                            <h2 className="fs-20 fw-bolder mb-4">Login to your account</h2>
                            <form className="w-100 mt-4 pt-2" onSubmit={handleSubmit}>
                                <div className="mb-4">
                                    <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Email or Username"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <input
                                        type="password"
                                        className="form-control"
                                        placeholder="Password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                </div>
                                {error && <div className="text-danger mb-3">{error}</div>}
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                        <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" id="rememberMe" />
                                            <label className="custom-control-label c-pointer" htmlFor="rememberMe">Remember Me</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5">
                                    <button type="submit" className="btn btn-lg btn-primary w-100">Login</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Login;
