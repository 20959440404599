import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

function Projects(props) {

    const [projects, setProjects] = useState([]);
    const location = useLocation();

    const navigate = useNavigate();

    const handleEdit = (singleProjects) => {
        navigate("/project-form", { state: { editData: singleProjects } });
    };

    useEffect(() => {
        const items = JSON.parse(localStorage.getItem("projects"));
        if (items?.length) {
            setProjects(items);
        } else {
            localStorage.setItem("projects", JSON.stringify([
                {
                    Id: "#321456",
                    Name: "Nayra Constructions",
                    WorkOrders: 2,
                },
                {
                    Id: "#321457",
                    Name: "Shree Sai Constructions",
                    WorkOrders: 3,
                }
            ]));

            setProjects([
                {
                    Id: "#321456",
                    Name: "Nayra Constructions",
                    WorkOrders: 2,
                },
                {
                    Id: "#321457",
                    Name: "Shree Sai Constructions",
                    WorkOrders: 3,
                }
            ])
        }
    }, [location]);

    function deleteProject(id) {
        let projects = JSON.parse(localStorage.getItem("projects"));
        projects = projects.filter((project) => project.Id !== id);
        localStorage.setItem("projects", JSON.stringify(projects));
        setProjects(projects);
    }

    return (
        <>
            <main className='nxl-container'>
                <div className='nxl-content'>

                    <div className="page-header">
                        <div className="page-header-left d-flex align-items-center">
                            <div className="page-header-title">
                                <h5 className="m-b-10">Projects</h5>
                            </div>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><NavLink to={"/"}>Home</NavLink></li>
                                <li className="breadcrumb-item">Projects</li>
                            </ul>
                        </div>
                        <div className="page-header-right ms-auto">
                            <div className="page-header-right-items">
                                <div className="d-flex d-md-none">
                                    <a href="javascript:void(0)" className="page-header-right-close-toggle">
                                        <i className="feather-arrow-left me-2"></i>
                                        <span>Back</span>
                                    </a>
                                </div>
                                <div className="d-flex align-items-center gap-2 page-header-right-items-wrapper">

                                    <NavLink to={"/project-form"} className="btn btn-primary">
                                        <a>
                                            <i className="feather-plus me-2"></i>
                                            <span>New Projects</span>
                                        </a>
                                    </NavLink>
                                </div>
                            </div>
                            <div className="d-md-none d-flex align-items-center">
                                <a href="javascript:void(0)" className="page-header-right-open-toggle">
                                    <i className="feather-align-right fs-20"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="main-content">
                        <div class="col-xxl-4">
                            <div class="card stretch stretch-full">
                                <div class="card-header">
                                    <h5 class="card-title">Project Status</h5>
                                </div>
                                <div class="card-body custom-card-action">
                                    <div class="mb-3">
                                        {
                                            projects.map(project => {
                                                return <>
                                                    <div class="mb-4 pb-1 d-flex">
                                                        <div class="d-flex w-50 align-items-center me-3">
                                                            <img src="assets/images/brand/app-store.png" alt="laravel-logo" class="me-3" width="35" />
                                                            <div>
                                                                <a href="javascript:void(0);" class="text-truncate-1-line">{project.Name}</a>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex flex-grow-1 align-items-center">
                                                            <div class="d-flex flex-grow-1 align-items-center">
                                                                <span class="text-muted">{project.WorkOrders} WorkOrders</span>
                                                            </div>
                                                        </div>
                                                        <div className="hstack gap-2 justify-content-end">
                                                            <button onClick={() => handleEdit(project)} className="avatar-text avatar-md">
                                                                <i className="feather feather-edit"></i>
                                                            </button>
                                                            <a onClick={() => deleteProject(project.Id)} className="avatar-text avatar-md">
                                                                <i className="feather feather-delete"></i>
                                                            </a>
                                                        </div>
                                                    </div >
                                                </>
                                            })
                                        }
                                        <hr class="border-dashed my-3" />
                                        {/* <div class="mb-4 pb-1 d-flex">
                            <div class="d-flex w-50 align-items-center me-3">
                                <img src="assets/images/brand/figma.png" alt="figma-logo" class="me-3" width="35" />
                                <div>
                                    <a href="javascript:void(0);" class="text-truncate-1-line">Shree Sai Constructions</a>
                                </div>
                            </div>
                            <div class="d-flex flex-grow-1 align-items-center">
                                <div class="progress w-100 me-3 ht-5">
                                    <div class="progress-bar bg-primary" role="progressbar" style={{ "width": "86%" }} aria-valuenow="86" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <span class="text-muted">86%</span>
                            </div>
                        </div> */}
                                    </div>
                                </div>
                                {/* <a href="javascript:void(0);" class="card-footer fs-11 fw-bold text-uppercase text-center">Upcomming Projects</a> */}
                            </div>
                        </div>

                    </div>

                </div>

            </main>
            {/* <footer class="footer">
                <p class="fs-11 text-muted fw-medium text-uppercase mb-0 copyright">
                    <span>Copyright ©2024</span>
                </p>
            </footer> */}
        </>
    );
}

export default Projects;