import './App.css';

import React, { Suspense } from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';

import Navbar from './Components/Navbar';
import SideNav from './Components/SideNav';
import CreateInventory from './View/CreateInventory';
import Projects from './View/Projects';
import CreateProjects from './View/CreateProjects';
import Login from './View/Login';

const Home = React.lazy(() => import("./View/Home"));
const Inventory = React.lazy(() => import("./View/Inventory"));

function App() {

  const location = useLocation();
  const navigate = useNavigate();

  const isLogin = location.pathname === '/login';
  const token = localStorage.getItem('token');

  // if (!location.pathname.includes("admin")) {
  //   localStorage.removeItem("token");
  // }

  // const token = localStorage.getItem('token');
  // if (!token) {
  //   navigate('/login');
  // }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      {/* <ScrollToTop /> */}
      {
        !isLogin &&
        <>
          <Navbar />
          <SideNav />
        </>
      }
      <Routes>
        <Route
          path="/"
          element={token ? <Home /> : <Navigate to="/login" />}
        />
        <Route
          path="/dashboard"
          element={token ? <Home /> : <Navigate to="/login" />}
        />
        <Route
          path="/inventory"
          element={token ? <Inventory key={Date.now()} /> : <Navigate to="/login" />}
        />
        <Route
          path="/inventory-form"
          element={token ? <CreateInventory /> : <Navigate to="/login" />}
        />
        <Route
          path="/projects"
          element={token ? <Projects /> : <Navigate to="/login" />}
        />
        <Route
          path="/project-form"
          element={token ? <CreateProjects /> : <Navigate to="/login" />}
        />
        <Route
          path="/login"
          element={!token ? <Login /> : <Navigate to="/" />}
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Suspense>
  );
}

export default App;
